import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Colored = styled.span`
  color: #fb52b7;
`;

export interface StatsProps {
  itemsRemaining: number;
}

const Stats = (props: StatsProps) => {
  const classes = useStyles();
  return (
    <Box component="div" m={2}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} md={4}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Total Supply
              </Typography>
              <Box m={1} p={2}>
                <Typography variant="h4" component="p">
                  <Colored>5,555</Colored>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Price
              </Typography>
              <Box m={1} p={2}>
                <Typography variant="h4" component="p">
                  0.1 S<Colored>O</Colored>L
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                remaining
              </Typography>
              <Box m={1} p={2}>
                <Typography variant="h4" component="p">
                  {props.itemsRemaining ? props.itemsRemaining : "Loading"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                MINT <Colored>D</Colored>ATE
              </Typography>
              <Box m={1} p={2}>
                <Typography variant="h4" component="p">
                  2021-10-30 10:00:00AM <Colored>GMT</Colored>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stats;
